import { all, call, put, takeEvery } from "redux-saga/effects";
import { ssoApi, requestWithCustomClientId } from "../../axios/interceptor";
import { SSO_API_URL } from "../../constants/app-constant";
import { subscriber } from "../../axios/interceptor";
import {
  OnboardingActionTypes,
  fetchAllApplicationsUsingRoleSuccess,
  fetchAllApplicationsSuccess,
  onboardToApplicationSuccess,
  onboardToApplicationError,
  verifyEmailSuccess,
  verifyEmailError,
} from "./onboarding-actions";

export function* FetchAllApplicationsUsingRoleAsync(action) {
  const { userEmail, clientName } = action.payload;
  const clientSearch =
    clientName && clientName !== "" ? `&clientId=${clientName}` : "";

  try {
    const response = yield call(
      ssoApi.get,
      `account/getuserbyemail?email=${userEmail}${clientSearch}`
    );
    yield put(fetchAllApplicationsUsingRoleSuccess(response));
  } catch (err) {
    console.log(err);
  }
}

export function* WatchFetchAllApplicationsUsingRoleflow() {
  yield takeEvery(
    OnboardingActionTypes.FETCHING_DATA,
    FetchAllApplicationsUsingRoleAsync
  );
}

export function* FetchAllApplicationsAsync(action) {
  try {
    const { pageNo, pageSize, clientName } = action.payload;
    const clientSearch =
      clientName && clientName !== "" ? `&clientName=${clientName}` : "";
    let response;
    if (pageNo && pageSize) {
      response = yield call(
        ssoApi.get,
        `/client/list/v2?pageNo=${pageNo}&pageSize=${pageSize}${clientSearch}`
      );
    } else {
      response = yield call(ssoApi.get, `/client/list`);
    }
    yield put(fetchAllApplicationsSuccess(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* WatchFetchAllApplicationsflow() {
  yield takeEvery(
    OnboardingActionTypes.FETCHING_ALL_APPLICATIONS_DATA,
    FetchAllApplicationsAsync
  );
}

export function* VerifyEmailAsync(action) {
  const { userEmail } = action.payload;
  try {
    const response = yield call(
      ssoApi.get,
      `account/getuserbyemail?email=${userEmail}`
      );
    if (response.status === 200) {
      yield put(verifyEmailSuccess(response));
    } else {
      yield put(verifyEmailError(response));
    }
  } catch (err) {}
}

export function* WatchVerifyEmailflow() {
  yield takeEvery(OnboardingActionTypes.VERIFYING_EMAIL, VerifyEmailAsync);
}

export function* OnboardUserAsync({
  payload: { userData, closeModal, onboardAppName },
}) {
  try {
    const req = yield requestWithCustomClientId(SSO_API_URL, onboardAppName);
    const response = yield call(
      req.post,
      "account/addClaims",
      JSON.stringify(userData)
    );
    if (response.status === 200) {
      yield put(onboardToApplicationSuccess(response));
    } else {
      yield put(onboardToApplicationError(response));
    }
  } catch (err) {
    console.log("hello", err);
    yield put(onboardToApplicationError(err));
    subscriber.next(err.response);
  }
}

export function* WatchUserOnboarding() {
  yield takeEvery(
    OnboardingActionTypes.ONBOARD_TO_APPLICATION,
    OnboardUserAsync
  );
}

export default function* onboardingSaga() {
  yield all([
    call(WatchFetchAllApplicationsUsingRoleflow),
    call(WatchFetchAllApplicationsflow),
    call(WatchUserOnboarding),
    call(WatchVerifyEmailflow),
  ]);
}
